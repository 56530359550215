import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/esm/Modal";
import {
  useForm,
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";
import useExpenseGroups from "./ExpenseGroupHook";

export default function ProcessRule(props) {
  const [isSaving, setIsSaving] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    control,
  } = useForm();

  const expenseGroups = useExpenseGroups();

  useEffect(() => {
    if (props.processRule.expenseGroup)
      setValue("expenseGroup", props.processRule.expenseGroup);
  }, [expenseGroups]);

  useEffect(() => {}, []);

  const onSubmit = (data) => {
    console.log(data);
    fetch('/api/processRule',
        {method: 'POST',
        headers: {
            "Content-Type": "application/json; charset=utf-8"
        },
        credentials: 'include',
        body: JSON.stringify(data)})
        .then((response) => {
            if (!response.ok) throw Error(response.statusText);
            props.submitCallback();
        }).catch((err) => {
            console.error(err);
            setIsSaving(false);
        });
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want to delete?")) {
      
    }
  };

  return (
    <Modal
      show={props.processRule ? true : false}
      onHide={props.closeCallback}
      centered
      scrollable
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Process Rule</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {props.processRule && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="hidden"
                defaultValue={props.processRule._id}
                {...register('_id')}
              />

              <div className="form-group" id="loadedFromScrapeAccountDiv">
                <label for="loadedFromScrapeAccount">Scrape Account</label>
                <select
                  className="form-control"
                  defaultValue={props.processRule.loadedFromScrapeAccount}
                  {...register('loadedFromScrapeAccount',{
                    required: "Scrape Account is required",
                  })}
                >
                  <option value="CIBCVisa">CIBC Visa</option>
                  <option value="CIBCJoint">CIBC Joint</option>
                  <option value="CIBCMark">CIBC Mark</option>
                  <option value="CIBCMell">CIBC Mell</option>
                  <option value="Canadian Tire">Canadian Tire</option>
                  <option value="RBCJoint">RBCJoint</option>
                  <option value="RBCMark">RBCMark</option>
                  <option value="RBCMell">RBCMell</option>
                  <option value="RBCMellVisa">RBCMellVisa</option>
                  <option value="Scotia">Scotia</option>
                </select>
              </div>

              <div className="form-group" id="expenseGroupDiv">
                <label for="expenseGroup">Expense Group</label>
                <select
                  className="form-control"
                  {...register('expenseGroup')}
                >
                  <option></option>
                  {expenseGroups &&
                    expenseGroups.map((expenseGroup) => (
                      <option key={expenseGroup._id} value={expenseGroup._id}>
                        {expenseGroup.fullName}
                      </option>
                    ))}
                </select>
              </div>

              <div className="form-group" id="matchTypeDiv">
                <label for="matchType">Match Type</label>
                <select
                  className={
                    "form-control" + (errors.matchType ? " is-invalid" : "")
                  }
                  {...register('matchType',{
                    required: "Match Type is required",
                  })}
                  defaultValue={props.processRule.matchType}
                >
                  <option></option>
                  <option value="Exact">Exact</option>
                  <option value="RegEx">RegEx</option>
                </select>
                {errors.matchType && (
                  <div className="invalid-feedback">
                    {errors.matchType.message}
                  </div>
                )}
              </div>

              <div classNamne="form-group">
                <label for="matchText">Match Text</label>
                <textarea
                  className="form-control"
                  {...register('matchText',{
                    required: "Match Text is required",
                  })}
                  defaultValue={props.processRule.matchText}
                ></textarea>
              </div>
            </form>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        {/* {props.processRule._id ? (
          <button
            type="button"
            className="btn btn-light btn-sm"
            onClick={onDelete}
          >
            Delete
          </button>
        ) : (
          <div></div>
        )} */}
        <div></div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleSubmit(onSubmit)()}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}
