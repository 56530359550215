import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/esm/Modal";
import {
  LineChart,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  ResponsiveContainer,
} from "recharts";
import {
  useForm,
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from "react-hook-form";

export default function BudgetForcastingReport(props) {
  const [reportData, setReportData] = useState(null);
  const [shouldRunReport, setShouldRunReport] = useState(false);
  const [defaultParameters, setDefaultParameters] = useState(null);
  const [budgetProjectionData, setBudgetProjectionData] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    control,
  } = useForm({
    defaultValues: {
      incomes: [{ value: null }],
      endYear: new Date().getFullYear(),
    },
  });

  const incomeType = watch("incomeType", null);

  const {
    fields: incomes,
    append: appendIncome,
    remove: removeIncome,
  } = useFieldArray({
    control,
    name: "incomes",
  });

  // const { fields: federalTax, append: appendFederalTax } = useFieldArray({
  //   control,
  //   name: "federalTax",
  // });

  // const { fields: provincialTax, append: appendProvincialTax } = useFieldArray({
  //   control,
  //   name: "provincialTax",
  // });

  // const { fields: omers, append: appendOmers } = useFieldArray({
  //   control,
  //   name: "omers",
  // });

  const dollarFormatter = (value) => `$${value}`;

  function runReport() {}

  const onSubmit = (data) => {
    fetch(`/api/budget-projection?incomeType=${data.incomeType}&endYear=${data.endYear}`)
    .then((response) => {
      if (!response.ok) throw Error(response.statusText);
      return response.json();
    }).then((budgetProjection) => {
      console.log(budgetProjection);
      setReportData(budgetProjection);
    })
    .catch((err) => {
      console.error(err);
    });
  };

  const fetchDefaultParameters = () => {
    fetch(`/api/budget-projection-parameters`)
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        return response.json();
      })
      .then((params) => {
        setDefaultParameters(params);
        // appendFederalTax(params.federalTax);
        // appendProvincialTax(params.provincialTax);
        // appendOmers(params.omers);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    //fetchDefaultParameters();
  }, []);

  return (
    <div>
      <div className="p-3 mb-2 bg-light text-dark">
        <h3>Budget Forcasting Report</h3>
        <div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => setShouldRunReport(true)}
          >
            Run Report
          </button>
        </div>
      </div>
      <div>
        {reportData && (
          <ResponsiveContainer width="100%" height={600}>
            <ComposedChart data={reportData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis tickFormatter={dollarFormatter} />
              <Tooltip />
              <Line type="monotone" dataKey="income" stroke="#ff7300" />
              <Bar dataKey="budget" fill="#227399" stackId="a" />
              <Bar dataKey="adjustment" fill="#42A7D5" stackId="a" />
              <Bar dataKey="project" fill="#8ECAE6" stackId="a" />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </div>
      <Modal
        show={shouldRunReport}
        onHide={() => setShouldRunReport(false)}
        centered
        scrollable
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Report Parameters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <label htmlFor="endYear">End Year</label>
                <input
                  type="number"
                  className={
                    "form-control" + (errors.endYear ? " is-invalid" : "")
                  }
                  {...register('endYear', {
                    required: "Year is required",
                  })}
                />
                {errors.endYear && (
                  <div className="invalid-feedback">
                    {errors.endYear.message}
                  </div>
                )}
              </div>
              <div className="form-group">
              <div className="form-check">
                <input
                  className={"form-check-input" + (errors.incomeType ? " is-invalid" : "")}
                  type="radio"
                  value="autoIncome"
                  id="incomeType1"
                  {...register("incomeType", { required: true })}
                />
                <label className="form-check-label" for="incomeType1">
                  Income Based on Last Year
                </label>
              </div>
              <div className="form-check">
                <input
                  className={"form-check-input" + (errors.incomeType ? " is-invalid" : "")}
                  type="radio"
                  id="incomeType2"
                  value="manualIncome"
                  {...register("incomeType", { required: true })}
                />
                <label className="form-check-label" for="incomeType2">
                  Manual Income Entry
                </label>
              </div>
              {errors.incomeType && (
                  <div className="invalid-feedback">
                    {errors.incomeType.message}
                  </div>
              )}
              </div>
              {incomeType === "manualIncome" &&
                incomes.map((item, index) => (
                  <div className="form-group" style={{ position: "relative" }}>
                    {index > 0 && (
                      <i
                        className="fas fa-times"
                        style={{
                          position: "absolute",
                          right: "5px",
                          top: "5px",
                          fontSize: "1.5rem",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          removeIncome(index);
                        }}
                      ></i>
                    )}
                    <label htmlFor={`incomes[${index}].value`}>Income</label>
                    <input
                      key={item.id}
                      type="number"
                      className={
                        "form-control" +
                        (errors.incomes && errors.incomes[index]
                          ? " is-invalid"
                          : "")
                      }
                      defaultValue={item.value}
                      {...register(`incomes[${index}].value`,{
                        required: "Income is required",
                        min: { value: 0, message: "Number must be positive" },
                      })}
                    />
                    {errors.incomes &&
                      errors.incomes[index] &&
                      errors.incomes[index].value && (
                        <div className="invalid-feedback">
                          {errors.incomes[index].value.message}
                        </div>
                      )}
                  </div>
                ))}
              {incomeType === "manualIncome" && (
                <i
                className="fas fa-plus"
                  style={{
                    fontSize: "1.5rem",
                    cursor: "pointer",
                  }}
                  onClick={() => appendIncome({ value: null })}
                ></i>
              )}

              {/* {federalTax.map((item, index) => (
                <div className="form-row">
                  <div className="form-group col">
                    <label htmlFor={`federalTax[${index}].income`}>
                      Income Limit
                    </label>
                    <input
                      key={item.id}
                      type="number"
                      className={
                        "form-control" +
                        (errors.federalTax &&
                        errors.federalTax[index] &&
                        errors.federalTax[index].income
                          ? " is-invalid"
                          : "")
                      }
                      name={`federalTax[${index}].income`}
                      defaultValue={item.income}
                      ref={register({
                        required: "Income limit is required",
                      })}
                    />
                  </div>
                  <div className="form-group col">
                    <label htmlFor={`federalTax[${index}].rate`}>
                      Tax Rate
                    </label>
                    <input
                      key={item.id}
                      type="number"
                      className={
                        "form-control" +
                        (errors.federalTax &&
                        errors.federalTax[index] &&
                        errors.federalTax[index].rate
                          ? " is-invalid"
                          : "")
                      }
                      name={`federalTax[${index}].rate`}
                      defaultValue={item.rate}
                      ref={register({
                        required: "Tax rate is required",
                      })}
                    />
                  </div>
                </div>
              ))}

              {provincialTax.map((item, index) => (
                <div className="form-row">
                  <div className="form-group col">
                    <label htmlFor={`provincialTax[${index}].income`}>
                      Income Limit
                    </label>
                    <input
                      key={item.id}
                      type="number"
                      className={
                        "form-control" +
                        (errors.provincialTax &&
                        errors.provincialTax[index] &&
                        errors.provincialTax[index].income
                          ? " is-invalid"
                          : "")
                      }
                      name={`provincialTax[${index}].income`}
                      defaultValue={item.income}
                      ref={register({
                        required: "Income limit is required",
                      })}
                    />
                  </div>
                  <div className="form-group col">
                    <label htmlFor={`provincialTax[${index}].rate`}>
                      Tax Rate
                    </label>
                    <input
                      key={item.id}
                      type="number"
                      className={
                        "form-control" +
                        (errors.provincialTax &&
                        errors.provincialTax[index] &&
                        errors.provincialTax[index].rate
                          ? " is-invalid"
                          : "")
                      }
                      name={`provincialTax[${index}].rate`}
                      defaultValue={item.rate}
                      ref={register({
                        required: "Tax rate is required",
                      })}
                    />
                  </div>
                </div>
              ))}

              {omers.map((item, index) => (
                <div className="form-row">
                  <div className="form-group col">
                    <label htmlFor={`omers[${index}].income`}>
                      Income Limit
                    </label>
                    <input
                      key={item.id}
                      type="number"
                      className={
                        "form-control" +
                        (errors.omers &&
                        errors.omers[index] &&
                        errors.omers[index].income
                          ? " is-invalid"
                          : "")
                      }
                      name={`omers[${index}].income`}
                      defaultValue={item.income}
                      ref={register({
                        required: "Income limit is required",
                      })}
                    />
                  </div>
                  <div className="form-group col">
                    <label htmlFor={`omers[${index}].rate`}>
                      Tax Rate
                    </label>
                    <input
                      key={item.id}
                      type="number"
                      className={
                        "form-control" +
                        (errors.omers &&
                        errors.omers[index] &&
                        errors.omers[index].rate
                          ? " is-invalid"
                          : "")
                      }
                      name={`omers[${index}].rate`}
                      defaultValue={item.rate}
                      ref={register({
                        required: "Tax rate is required",
                      })}
                    />
                  </div>
                </div>
              ))} */}
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => handleSubmit(onSubmit)()}
          >
            Go
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
