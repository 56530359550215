import React, { useState, useEffect, useContext, useMemo } from "react";
import { useTable, useFilters } from "react-table";

export default function Logs(props) {
  const [loadingLogs, setLoadingLogs] = useState(true);
  const [logData, setLogData] = useState([]);

  const fetchLogs = async function () {
    setLoadingLogs(true);
    const logResponse = await fetch(`/api/logs`, {
      credentials: "include",
    });
    const logsJSON = await logResponse.json();
    setLogData(logsJSON);
    setLoadingLogs(false);
  };

  const refreshAfterFormAction = () => {
    fetchLogs();
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  const Table = ({ data, loading }) => {
    const columns = useMemo(
      () => [
        {
          Header: "Timestamp",
          accessor: "timestamp",

        },
        {
          Header: "Subject",
          accessor: "subject",
        },
        {
          Header: "Account",
          accessor: "account",
        },
        {
          Header: "Message",
          accessor: "message",
        },
      ],
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({ columns, data }, useFilters);

    return (
      <div>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} style={{position:'sticky', top:'50', backgroundColor: 'white'}}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {!loading && (
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{ paddingRight: "15px" }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {loading && (
          <div
            className="lds-ellipsis"
            style={{ width: "100%", marginLeft: "50%" }}
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <h3>Logs</h3>
      {logData && (
        <Table data={logData} loading={loadingLogs} />
      )}
    </div>
  );
}
