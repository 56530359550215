import React, { useState, useEffect, useContext } from "react";

export default function AmountCard(props) {
  function clearTempBalance(account) {
    if (
      window.confirm(
        `Are you sure you want to clear all transactions for ${account}`
      )
    ) {
      fetch("/api/clear-temp-balance", {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        credentials: "include",
        body: JSON.stringify({ account: account }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.error) {
            alert(data.error.message);
          } else if (data.success) {
            props.actionCallback();
          }
        });
    }
  }

  return (
    <div className="card bg-light mb-3">
      <div className="card-header">{props.title}</div>
      <div className="card-body text-center">
        {props.amounts &&
          props.amounts.map((amount) => (
            <div>
              {((amount.clearValue && amount.amount) || !amount.clearValue) && (
                <h5 className="card-title">{amount.name}</h5>
              )}
              {amount.clearValue && amount.amount && (
                <a href="#link" className="plainLink">
                  <h1 className="hover-icons-visible font-weight-bolder">
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(amount.amount)}{" "}
                    <i
                      className="fas fa-ban text-danger"
                      onClick={() => clearTempBalance(amount.name)}
                    ></i>
                  </h1>
                </a>
              )}
              {!amount.clearValue && (
                <h1 className="font-weight-bolder">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(amount.amount)}
                </h1>
              )}
            </div>
          ))}
      </div>
    </div>
  );
}
