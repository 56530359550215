import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "./App.scss";
import "./loader.css";
import Header from "./components/Header";
import Home from "./components/Home";
import Login from "./Login";
import Transactions from "./components/Transactions";
import BudgetDefs from "./components/BudgetDefs";
import Projects from "./components/Projects";
import ExpenseGroupReport from "./components/reports/ExpenseGroupReport";
import BudgetForcastingReport from "./components/reports/BudgetForcastingReport";
import ExpenseGroups from "./components/ExpenseGroups";
import Logs from "./components/Logs";

function App() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetch("/api/user")
      .then((response) => response.json())
      .then((theUser) => {
        setUser(theUser);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, []);
  return (
    <div>
      {user && (
        <BrowserRouter>
          <Header user={user} />
          <div className="main">
            <Route path="/" exact component={Home} />
            <Route path="/transactions" exact component={Transactions} />
            <Route path="/admin/budgetDefs" exact component={BudgetDefs} />
            <Route path="/admin/projects" exact component={Projects} />
            <Route
              path="/admin/expenseGroups"
              exact
              component={ExpenseGroups}
            />
            <Route
              path="/admin/logs"
              exact
              component={Logs}
            />
            <Route
              path="/report/expenseGroups"
              exact
              component={ExpenseGroupReport}
            />
            <Route
              path="/report/budgetForecasting"
              exact
              component={BudgetForcastingReport}
            />
          </div>
        </BrowserRouter>
      )}
      {!user && !isLoading && <Login />}
    </div>
  );
}

export default App;
