import React, { useState, useEffect, useContext } from "react";
import ExpenseGraphPart from './ExpenseGraphPart';

export default function Expenses(props){
    
    return (
        <table className="table table-sm">
            <tbody>
                { props.monthlyExpenses && props.monthlyExpenses.map(expense => 
                    <tr>
                        <td>
                            <ExpenseGraphPart {...expense} newTransactionCallback={props.newTransactionCallback}/>
                        </td>
                        <td style={{'verticalAlign': 'bottom'}}>
                            { expense.trend === 'up' && <i className="fas fa-arrow-up text-danger" title="Budget up from last month"></i>}
                            { expense.trend === 'down' && <i className="fas fa-arrow-down text-success" title="Budget down from last month"></i>}
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}