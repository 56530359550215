import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import useExpenseGroups from "./ExpenseGroupHook";

export default function ExpenseGroup(props) {
  const [isSaving, setIsSaving] = useState(true);
  const expenseGroups = useExpenseGroups(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    control,
  } = useForm();
  watch();

  const onSubmit = (data) => {
    setIsSaving(true);
    if(data.parent._id === '') data.parent = null;
    fetch("/api/expense-group", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        props.submitCallback();
      })
      .catch((err) => {
        console.error(err);
        setIsSaving(false);
      });
  };

  const onDelete = () => {
    if(window.confirm('Are you sure you want to delete?')){
      setIsSaving(true);
      fetch("/api/delete-expense-group", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({_id:props.expenseGroup._id}),
      }).then((response) => {
        if (!response.ok) throw Error(response.statusText);
        props.deleteCallback();
      })
      .catch((err) => {
        console.error(err);
        setIsSaving(false);
      });
    }
  };

  useEffect(() => {
    if(props.expenseGroup.parent) setValue("parent._id", props.expenseGroup.parent._id);
  }, [expenseGroups]);

  return (
    <Modal
        show={props.expenseGroup ? true : false}
        onHide={props.closeCallback}
        centered
        scrollable
        backdrop="static"
        keyboard={false}
    >
        <Modal.Header closeButton>
        <Modal.Title>
            {props.expenseGroup && props.expenseGroup._id ? (
            <div>Edit Expense Group</div>
            ) : (
            <div>New Expense Group</div>
            )}
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div>
      {props.expenseGroup && (
        <form id="budgetDefForm" onSubmit={handleSubmit(onSubmit)}>
          <input
              type="hidden"
              defaultValue={props.expenseGroup._id}
              {...register('_id')}
            />
            <div className="form-group">
                <label htmlFor="parent">Parent</label>
                <select
                  className="form-control" 
                  defaultValue={
                    props.parent ? props.parent._id : ''
                  }
                  {...register('parent._id')}
                >
                  <option value=""></option>
                  {expenseGroups &&
                    expenseGroups.filter(expenseGroup => !expenseGroup.parent && expenseGroup.name !== 'none').map((expenseGroup) => (
                      <option value={expenseGroup._id}>
                        {expenseGroup.fullName}
                      </option>
                    ))}
                </select>
                
              </div>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className={"form-control" + (errors.name ? " is-invalid" : "")}
              defaultValue={props.expenseGroup.name}
              {...register('name',{
                required: "Name is required",
              })}
            />
            {errors.name && (
              <div className="invalid-feedback">{errors.name.message}</div>
            )}
          </div>    
        </form>
      )}
    </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
            { props.expenseGroup._id ? <button type="button" className="btn btn-light btn-sm" onClick={onDelete}>
              Delete
            </button> : <div></div>}
            <button type="button" className="btn btn-primary" onClick={() => handleSubmit(onSubmit)()}>
              Save
            </button>
        </Modal.Footer>
    </Modal>
    
  );
}
