import React, { useState, useEffect, useContext, useMemo } from "react";
import { useTable, useFilters } from 'react-table'

export default function TransactionsTable(props){
    const Amount = (params) => {
        let style={textAlign:'right', fontWeight:'bold'};
        if(params.row.values.toAccount && params.row.values.toAccount === props.account) style.color = 'green';
        return <div style={style}>
             ${params.cell.value.toFixed(2)}
        </div>
    }

    const MyDate = (params) => {
        return <div style={{width:'100px'}}>
            {new Intl.DateTimeFormat('en-CA').format(new Date(params.cell.value))}
        </div>
    }

    const ExpenseGroup = (params) => {
        return <span className="hover-icons-visible-0"><a href="#link" className={`plainLink ${params.cell.value === 'none' ? 'text-warning font-weight-bold' : ''}`}>{params.cell.value}<i className="fas fa-filter ml-1" onClick={ () => params.column.setFilter(params.cell.value) }></i></a></span>
    }

    const Comment = (params) => {
        return <div className="text-truncate" style={{maxWidth:'500px'}}>
            {params.cell.value}
        </div>
    }

    const SelectColumnFilter = function ({
        column: { filterValue, setFilter, preFilteredRows, id },
      }) {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = React.useMemo(() => {
          const options = new Set()
          preFilteredRows.forEach(row => {
            options.add(row.values[id])
          })
          return [...options.values()]
        }, [id, preFilteredRows])

        if(filterValue && !options.find(option => option === filterValue)) options.push(filterValue);

        // Render a multi-select box
        return (
          <select
            value={filterValue}
            onChange={e => {
              setFilter(e.target.value || undefined)
            }}
          >
            <option value="">All</option>
            {options.sort().map((option, i) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </select>
        )
      }

      function DefaultColumnFilter({
        column: { filterValue, preFilteredRows, setFilter },
      }) {
        const count = preFilteredRows.length
      
        return (
            // <div></div>
          <input
            value={filterValue || ''}
            onChange={e => {
              setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            
          />
        )
      }

      const defaultColumn = React.useMemo(
        () => ({
          // Let's set up our default Filter UI
          Filter: DefaultColumnFilter,
        }),
        []
      )

    const columns = useMemo(
        () => [
          {
            Header: 'Date',
            HeaderText: 'Date',
            accessor: 'date',
            Cell: MyDate,
            disableFilters:true,
            
          },
          {
            Header: <div className="text-right">Amount</div>,
            HeaderText: 'Amount',
            accessor: 'amount',
            Cell: Amount,
            disableFilters:true,
            // Footer: info => {
            //   // Only calculate total visits if rows change
            //   const total = React.useMemo(
            //     () =>
            //       info.rows.reduce((sum, row) => row.values.amount + sum, 0),
            //     [info.rows]
            //   )

            //   return <div className="text-right font-weight-bold">${total.toFixed(2)}</div>
            // }
          },
          {
            Header: 'Expense Group',
            HeaderText: 'Expense Group',
            accessor: 'expenseGroup.fullName',
            Cell: ExpenseGroup,
            Filter: SelectColumnFilter, 
            filter: 'equals',
          },
          {
            Header: 'From Account',
            HeaderText: 'From Account',
            accessor: 'fromAccount',
            disableFilters:true,
          },
          {
            Header: 'To Account',
            HeaderText: 'To Account',
            accessor: 'toAccount',
            disableFilters:true,
          },
          {
            Header: 'Temp',
            HeaderText: 'Temp',
            accessor: 'tempFromAccount',
            disableFilters:true
          },
          {
            Header: 'Comments',
            HeaderText: 'Comments',
            accessor: 'comment',
            disableFilters:true,
            Cell: Comment
          },
          {
            Header: 'Project',
            HeaderText: 'Project',
            accessor: 'project.name',
            Filter: SelectColumnFilter, 
          },
        ],
        [props.account]
    );

    const data = props.data;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
        state
      } = useTable({ columns, data, defaultColumn, initialState:{filters:props.filter || []} }, useFilters);

    useEffect(() => {
      props.setFilter(state.filters);
    },[state.filters]);

    return (
      <div id="no-more-tables">
        <table {...getTableProps()} className="table">
       <thead>
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
               <th style={{position:'sticky', top:'50px', backgroundColor:'white',}}></th>
             {headerGroup.headers.map(column => (
               <th
                 {...column.getHeaderProps()}
                 style={{position:'sticky', top:'50px', backgroundColor:'white', }}
               >
                 <div>{column.canFilter ? column.render('Filter') : null}</div>
                 {column.render('Header')}
               </th>
             ))}
           </tr>
         ))}
       </thead>
       
       { !props.loading && 
       <tbody {...getTableBodyProps()}>
         {rows.map(row => {
           prepareRow(row)
           return (
             <tr {...row.getRowProps()}>
               <td
                 data-title="actions"
                 style={{paddingRight:'0px'}}
               >
                 <i
                   className="fas fa-edit pulse mr-2"
                   style={{ cursor: "pointer" }}
                   onClick={() => props.editRecord(row)}
                 ></i>
                 <span
                   className={
                     row.original.processRule
                       ? "hover-icons-visible-100"
                       : "hover-icons-visible-25"
                   }
                 >
                   <i
                     className={`far fa-play-circle mr-2`}
                     style={{ cursor: "pointer" }}
                     onClick={() => props.editProcessRule(row.original)}
                   ></i>
                   
                 </span>
                 {row.original.attachments?.length > 0 && (
                     <span className="hover-icons-visible-100">
                      <i
                        className="fas fa-paperclip"
                        style={{ cursor: "pointer" }}
                        onClick={() => props.showAttachments(row.original)}
                      ></i>
                     </span>
                   )}
               </td>
               {row.cells.map((cell) => {
                 return (
                   <td
                     data-title={cell.column.HeaderText}
                     {...cell.getCellProps()}
                     //  style={{paddingRight:'15px'}}
                   >
                     {cell.render("Cell")}
                   </td>
                 );
               })}
             </tr>
           );
         })}
       </tbody> }
       { !props.loading && <tfoot>
       {footerGroups.map(group => (
         <tr {...group.getFooterGroupProps()}>
           <td></td>
           {group.headers.map(column => (
             <td {...column.getFooterProps()}>{column.render('Footer')}</td>
           ))}
         </tr>
       ))}
     </tfoot> }
     </table>
     { props.loading && <div className="lds-ellipsis" style={{width:'100%', marginLeft:'50%'}}><div></div><div></div><div></div><div></div></div>}
     </div>
    );
}