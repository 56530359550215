import React, { useState } from "react";
import { Redirect } from "react-router-dom";

export default function StagedTransactions(props) {
  const [redirect, setRedirect] = useState(null);
  function dismissAlert() {
    document.querySelector("#stagedTransactionsAlert").classList.remove("show");
    setTimeout(
      () => document.querySelector("#stagedTransactionsAlert").remove(),
      250
    );
  }
  return (
    <div
      id="stagedTransactionsAlert"
      className="alert alert-warning alert-dismissible fade show"
      role="alert"
    >
      {redirect}
      <strong>
        {props.transactions.reduce((sum, group) => sum + group.count, 0)}{" "}
        uncategorized Transactions
      </strong>{" "}
      (
      {props.transactions.map((group, index) =>
        group._id.fromAccount ? (
          <a href="#link"
            className={index < props.transactions.length - 1 && "mr-2"}
            style={{color:'inherit'}}
            // style={{ cursor: "pointer" }}
            onClick={() =>
              setRedirect(
                <Redirect
                  to={`/transactions?account=${group._id.fromAccount}&expenseGroup.fullName=none`}
                />
              )
            }
          >
            {group._id.fromAccount} : {group.count}
          </a>
        ) : (
          <span className={index < props.transactions.length - 1 && "mr-2"}>
            Unknown : {group.count}
          </span>
        )
      )}
      )
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={dismissAlert}
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
  );
}
