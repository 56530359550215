import React, { useState, useEffect, useContext, useMemo } from "react";
import { useTable, useFilters } from "react-table";
import BudgetDef from "./BudgetDef";

export default function BudgetDefs(props) {
  const [loadingBudgetDefs, setLoadingBudgetDefs] = useState(true);
  const [budgetDefData, setBudgetDefData] = useState([]);
  const [budgetDefToEdit, setBudgetDefToEdit] = useState(null);

  const fetchBudgetDefs = async function () {
    setLoadingBudgetDefs(true);
    const budgetDefResponse = await fetch(`/api/budget-defs`, {
      credentials: "include",
    });
    const budgetDefs = await budgetDefResponse.json();
    setBudgetDefData(budgetDefs);
    setLoadingBudgetDefs(false);
  };

  const refreshAfterFormAction = () => {
    fetchBudgetDefs();
    setBudgetDefToEdit(null);
  };

  useEffect(() => {
    fetchBudgetDefs();
  }, []);

  const Table = ({ data, loading }) => {
    const Amount = (params) => {
      return (
        <div style={{ textAlign: "right", fontWeight: "bold" }}>
          {params.cell.value && `$${params.cell.value.toFixed(2)}`}
        </div>
      );
    };

    const columns = useMemo(
      () => [
        {
          Header: "Account",
          accessor: "account",
        },
        {
          Header: "Name",
          accessor: "name",
        },
        {
          Header: <div style={{ textAlign: "right" }}>Amount</div>,
          accessor: "amount",
          Cell: Amount,
        },
        {
          Header: "Expense Group",
          accessor: "expenseGroup.fullName",
        },
        {
          Header: "Type",
          accessor: "type",
        },
        {
          Header: "From",
          accessor: "monthFrom",
        },
        {
          Header: "To",
          accessor: "monthTo",
        },
      ],
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({ columns, data }, useFilters);

    return (
      <div>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th style={{position:'sticky', top:'50', backgroundColor: 'white', }}>
                  <i
                    className="fas fa-plus"
                    style={{ marginLeft: "5px", cursor: "pointer" }}
                    onClick={() => setBudgetDefToEdit({})}
                  ></i>
                </th>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} style={{position:'sticky', top:'50', backgroundColor: 'white'}}>
                    {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {!loading && (
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    <td style={{ paddingRight: "15px" }}>
                      <i
                        className="fas fa-edit pulse"
                        style={{ cursor: "pointer" }}
                        onClick={() => setBudgetDefToEdit(row.original)}
                      ></i>
                    </td>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{ paddingRight: "15px" }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {loading && (
          <div
            className="lds-ellipsis"
            style={{ width: "100%", marginLeft: "50%" }}
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <h3>Budget Definitions</h3>
      {budgetDefData && (
        <Table data={budgetDefData} loading={loadingBudgetDefs} />
      )}
      {budgetDefToEdit && (
        <BudgetDef
          budgetDef={budgetDefToEdit}
          submitCallback={refreshAfterFormAction}
          deleteCallback={refreshAfterFormAction}
          closeCallback={() => setBudgetDefToEdit(null)}
        />
      )}
    </div>
  );
}
