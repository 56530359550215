import { useState, useEffect } from "react";

export default function useExpenseGroups(all){
    const [expenseGroups, setExpenseGroups] = useState(null);

    const fetchExpenseGroups = () => {
      fetch(`/api/${all ? '' : 'selectable-'}expense-groups`)
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        return response.json();
      }).then(returnedExpenseGroups => {
        setExpenseGroups(returnedExpenseGroups);
      }).catch((err) => {
        console.error(err);
      });
    }

    useEffect(() => {
      fetchExpenseGroups();
    }, []);

    return expenseGroups;
  }