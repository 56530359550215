import React, { useState, useEffect, useContext, useMemo } from "react";
import { useTable, useFilters } from "react-table";
import ExpenseGroup from './ExpenseGroup';

export default function ExpenseGroups(props) {
  const [loadingExpenseGroups, setLoadingExpenseGroups] = useState(true);
  const [expenseGroupData, setExpenseGroupData] = useState([]);
  const [expenseGroupToEdit, setExpenseGroupToEdit] = useState(null);

  const fetchExpenseGroups = async function () {
    setLoadingExpenseGroups(true);
    const expenseGroupResponse = await fetch(`/api/expense-groups`, {
      credentials: "include",
    });
    const expenseGroups = await expenseGroupResponse.json();
    setExpenseGroupData(expenseGroups);
    setLoadingExpenseGroups(false);
  };

  const refreshAfterFormAction = () => {
    fetchExpenseGroups();
    setExpenseGroupToEdit(null);
  };

  useEffect(() => {
    fetchExpenseGroups();
  }, []);

  const Table = ({ data, loading }) => {
    const columns = useMemo(
      () => [
        {
          Header: "Parent",
          accessor: "parent.name",
        },
        {
          Header: "Name",
          accessor: "name",
        },
      ],
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({ columns, data }, useFilters);

    return (
      <div>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th style={{position:'sticky', top:'50', backgroundColor: 'white', }}>
                  <i
                    className="fas fa-plus"
                    style={{ marginLeft: "5px", cursor: "pointer" }}
                    onClick={() => setExpenseGroupToEdit({})}
                  ></i>
                </th>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} style={{position:'sticky', top:'50', backgroundColor: 'white'}}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {!loading && (
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    <td style={{ paddingRight: "15px" }}>
                      <i
                        className="fas fa-edit pulse"
                        style={{ cursor: "pointer" }}
                        onClick={() => setExpenseGroupToEdit(row.original)}
                      ></i>
                    </td>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{ paddingRight: "15px" }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {loading && (
          <div
            className="lds-ellipsis"
            style={{ width: "100%", marginLeft: "50%" }}
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <h3>Expense Groups</h3>
      {expenseGroupData && (
        <Table data={expenseGroupData} loading={loadingExpenseGroups} />
      )}
      {expenseGroupToEdit && (
        <ExpenseGroup
          expenseGroup={expenseGroupToEdit}
          submitCallback={refreshAfterFormAction}
          deleteCallback={refreshAfterFormAction}
          closeCallback={() => setExpenseGroupToEdit(null)}
        />
      )}
    </div>
  );
}
