import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip,ResponsiveContainer
} from 'recharts';

export default function ExpenseGraphPart(props){
    // const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    const [expenseGraph, setExpenseGraph] = useState(null);

    const toggleExpenseGroupGraph = function(){
        if(expenseGraph) setExpenseGraph(null);
        else showExpenseGroupGraph(props.expenseGroup._id,props.expenseGroupFullName);
    }

    function expenseClicked(event, data){
        const startDate = new Date(data.payload._id.year, data.payload._id.month-1, 1);
        const endDate = new Date(data.payload._id.year, data.payload._id.month, 0);
        setExpenseGraph(<Redirect to={`/transactions?expenseGroup.fullName=${props.expenseGroupFullName}&startDate=${startDate}&endDate=${endDate}`}/>);
    }

    const showExpenseGroupGraph = function(expenseGroupId, expenseGroupName){
        let fromMonth = new Date();
        fromMonth.setMonth(fromMonth.getMonth() - 14);
        fromMonth.setDate(1);
        fromMonth = new Date(fromMonth.getFullYear(), fromMonth.getMonth());
        let toMonth = new Date();
        toMonth.setMonth(toMonth.getMonth() + 1);
        toMonth.setDate(0);
        toMonth = new Date(toMonth.getFullYear(), toMonth.getMonth(), toMonth.getDate());
        // build url to fetch chart data
        let url = new URL(window.location.protocol + window.location.hostname + ':' + window.location.port + '/api/historical-monthly-expenses');
        let params = {fromDate: fromMonth, toDate: toMonth, fromAccount: 'Joint Checking', expenseGroup: expenseGroupId};
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        fetch(url, {credentials: 'include'})
        .then(response => { return response.json(); })
        .then(data => {
            data.sort((a, b) => {
                if(parseInt(a._id.year) === parseInt(b._id.year)) return (parseInt(a._id.month) - parseInt(b._id.month));
                else return (parseInt(a._id.year) - parseInt(b._id.year));
            });
            data.forEach(record => {
                record.name=`${record._id.year}-${record._id.month}`;
                record.totalAmount = Math.round(record.expenseGroups ? record.expenseGroups[0].totalAmount : 0);
            });
            setExpenseGraph(<ResponsiveContainer width="100%" height={200}>
                <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis/>
                    <Tooltip />
                    <Line type="monotone" dataKey="totalAmount" stroke="#8884d8" strokeWidth="2" activeDot={{ r: 8, onClick: expenseClicked}}/>
                </LineChart>
            </ResponsiveContainer>); 
        });
    }

    return (
        <div>
            <div>
                <a id={`expenseMonth${props.expenseGroup._id}`} href="#link" className="plainLink" onClick={toggleExpenseGroupGraph}>
                    { props.expenseGroupFullName }
                </a>
                { props.newTransactionCallback && <i className="fas fa-plus" style={{marginLeft:'5px', cursor:'pointer'}} onClick={() => props.newTransactionCallback(props.expenseGroup._id)}></i> }
            </div>
            <div className="progress" style={{backgroundColor: 'darkgray'}}>
                <div className={`progress-bar ${props.status === 'danger' ? 'bg-danger' : props.status === 'warning' ? 'bg-warning' : 'bg-success'}`} role="progressbar" style={{width: `${props.percentBudget}%`,  'ariaValuenow':'25', 'ariaValuemin':'0', 'ariaValuemax':'100'}}>
                    {/* { `$${props.totalAmount} / $${props.budgetAmount}` } */}
                </div>
                <div style={{
                    width: '100%',
                    position: 'absolute',
                    lineHeight: 'normal',
                    color: 'white',
                    textAlign: 'center'
                }}> { `$${props.totalAmount} / $${props.budgetAmount}` }</div>
            </div>
            {expenseGraph}
        </div>
    )
}