import React, { useState, useEffect, useContext, useMemo } from "react";
import { useTable, useFilters } from "react-table";
import Project from "./Project";

export default function Projects(props) {
  const [loadingProjects, setLoadingProjects] = useState(true);
  const [projectData, setProjectData] = useState([]);
  const [projectToEdit, setProjectToEdit] = useState(null);

  const fetchProjects = async function () {
    setLoadingProjects(true);
    const budgetDefResponse = await fetch(`/api/projects`, {
      credentials: "include",
    });
    const projects = await budgetDefResponse.json();
    projects.sort((a,b) => a.statusOrder > b.statusOrder ? 1 : a.statusOrder < b.statusOrder ? -1 : 0);
    setProjectData(projects);
    setLoadingProjects(false);
  };

  const refreshAfterFormAction = () => {
    fetchProjects();
    setProjectToEdit(null);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const Table = ({ data, loading }) => {
    const Amount = (params) => {
      return (
        <div style={{ textAlign: "right", fontWeight: "bold" }}>
          {params.cell.value && `$${params.cell.value.toFixed(2)}`}
        </div>
      );
    };

    const columns = useMemo(
      () => [
        {
          Header: "Account",
          accessor: "account",
        },
        {
          Header: "Name",
          accessor: "name",
        },
        {
          Header: <div style={{ textAlign: "right" }}>Budget Amount</div>,
          accessor: "budgetAmount",
          Cell: Amount,
        },
        {
          Header: "Status",
          accessor: "status",
        },
        {
          Header: "Year",
          Cell: params => <div>{params.row.original.startYear && `${params.row.original.startYear}${(params.row.original.endYear && params.row.original.endYear !== params.row.original.startYear) ? ` - ${params.row.original.endYear}` : ''}`}</div>,
          accessor: "startYear"
        },
        {
          Header: "Home Page",
          accessor: "displayOnHome",
        },
      ],
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({ columns, data }, useFilters);

    return (
      <div>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                <th style={{position:'sticky', top:'50', backgroundColor: 'white', }}>
                  <i
                    className="fas fa-plus"
                    style={{ marginLeft: "5px", cursor: "pointer" }}
                    onClick={() => setProjectToEdit({})}
                  ></i>
                </th>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} style={{position:'sticky', top:'50', backgroundColor: 'white'}}>
                    {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {!loading && (
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    <td style={{ paddingRight: "15px" }}>
                      <i
                        className="fas fa-edit pulse"
                        style={{ cursor: "pointer" }}
                        onClick={() => setProjectToEdit(row.original)}
                      ></i>
                    </td>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{ paddingRight: "15px" }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        {loading && (
          <div
            className="lds-ellipsis"
            style={{ width: "100%", marginLeft: "50%" }}
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <h3>Projects</h3>
      {projectData && (
        <Table data={projectData} loading={loadingProjects} />
      )}
      {projectToEdit && (
        <Project
          project={projectToEdit}
          submitCallback={refreshAfterFormAction}
          deleteCallback={refreshAfterFormAction}
          closeCallback={() => setProjectToEdit(null)}
        />
      )}
    </div>
  );
}
