import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/esm/Modal";
import ReactDatePicker from "react-datepicker";
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import {
  useForm,
  Controller,
  useFieldArray,
  useFormContext,
  useWatch
} from "react-hook-form";
import useExpenseGroups from "./ExpenseGroupHook";
const debounce = require("lodash.debounce");

export default function Transaction(props) {
  const [files, setFiles] = useState(
    props.transaction.attachments?.map((attachment) => ({
      source: attachment.filePath,
      options: {
        type: "local",
        file: {
          name: attachment.fileName,
          size: attachment.fileSize || 0,
        },
      },
    })) || []
  );
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [transaction, setTransaction] = useState(null);
  // const [fromAccount, setFromAccount] = useState(
  //   (props.transaction && props.transaction.fromAccount) || null
  // );
  const [projects, setProjects] = useState(null);
  const [isSaving, setIsSaving] = useState(true);
  const [originalAmount, setOriginalAmount] = useState(
    props.transaction.amount?.toFixed(2)
  );
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    formState: { errors }
  } = useForm();
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "additionalTransactions",
    }
  );

  const additionalTransactions = useWatch({control, name: 'additionalTransactions', defaultValue: []});
  const fromAccount = watch("fromAccount");

  const expenseGroups = useExpenseGroups();

  const onSubmit = (data) => {
    setIsSaving(true);
    if (!data.amount) data.amount = getValues("amount"); //if input was disabled
    if (!data.project._id) data.project = null;
    let truncatedDate = new Date(data.date);
    truncatedDate = new Date(
      truncatedDate.getFullYear(),
      truncatedDate.getMonth(),
      truncatedDate.getDate()
    );
    data.date = truncatedDate;
    // attachments
    data.attachments = files.map(file => ({serverId:file.serverId, fileName: file.filename, origin: file.origin}));
    data.deletedAttachments = deletedFiles?.map(file => ({fileName: file.filename, filePath: file.source}));
    fetch("/api/transaction", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        props.submitCallback();
      })
      .catch((err) => {
        console.error(err);
        setIsSaving(false);
      });
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want to delete?")) {
      setIsSaving(true);
      fetch("/api/delete-transaction", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ _id: props.transaction._id }),
      })
        .then((response) => {
          if (!response.ok) throw Error(response.statusText);
          props.deleteCallback();
        })
        .catch((err) => {
          console.error(err);
          setIsSaving(false);
        });
    }
  };

  const fetchProjects = () => {
    fetch(`/api/projects?account=${fromAccount}`)
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        return response.json();
      })
      .then((projects) => {
        setProjects(projects.sort((a,b) => a.name > b.name ? 1 : -1));
        setValue(
          "project._id",
          (props.transaction.project && props.transaction.project._id) || null
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const deleteFile = (source) => {
    let deletedFile = files.find(file => file.source === source);
    setDeletedFiles([...deletedFiles, deletedFile]);
    setFiles(files.filter(file => file.source !== source));
  }

  // Isolate amount field so watch won't trigger entire form to re-render
  function Amount({control, fields, errors, defaultValue}){
    const amount = useWatch({
      control,
      name: 'amount', 
      defaultValue: defaultValue 
    });

    return <input
      type="text"
      className={`form-control ${
        fields && fields.length > 0 && "font-weight-bold"
      } ${errors.amount && "is-invalid"} ${
        amount < 0 && "text-danger"
      }`}
      defaultValue={defaultValue}
      {...register('amount',{
        required: "Amount is required",
        min: { value: 0, message: "Number must be positive" },
      })}
      disabled={fields && fields.length > 0}
    />
  }

  useEffect(() => {
    let additionalSum = 0;
    for (let index = 0; index < additionalTransactions.length; index++) {
      additionalSum += Number(additionalTransactions[index].amount || 0);
    }
    setValue("amount", (originalAmount - additionalSum).toFixed(2));
  }, [additionalTransactions]);

  useEffect(() => {
    if (props.transaction.expenseGroup)
      setValue("expenseGroup._id", props.transaction.expenseGroup._id);
  }, [expenseGroups]);

  useEffect(() => {
    fetchProjects();
  }, [fromAccount]);

  useEffect(() => {
    //console.log(props.transaction.attachments);
  }, []);

  return (
    <Modal
      show={props.transaction ? true : false}
      onHide={props.closeCallback}
      centered
      scrollable
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.transaction && props.transaction._id ? (
            <div>Edit Transaction</div>
          ) : (
            <div>New Transaction</div>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {props.transaction && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="hidden"
                defaultValue={props.transaction._id}
                {...register('_id')}
              />
              <div className="form-group">
                <label htmlFor="date">Date</label>
                <Controller
                  control={control}
                  name="date"
                  defaultValue={
                    (props.transaction.date &&
                      new Date(props.transaction.date)) ||
                    new Date()
                  }
                  render={({ field: { onChange, value } }) => (
                    <ReactDatePicker
                      placeholderText="Select date"
                      onChange={onChange}
                      dateFormat="yyyy-MM-dd"
                      selected={value}
                      wrapperClassName={
                        "form-control" + (errors.date ? " is-invalid" : "")
                      }
                      className="form-control"
                    />
                  )}
                  rules={{ required: "Please enter a date" }}
                />
                {errors.date && (
                  <div className="invalid-feedback">{errors.date.message}</div>
                )}
              </div>
              <div
                className={
                  fields && fields.length > 0
                    ? "border border-secondary p-3"
                    : ""
                }
              >
                <div className="d-flex">
                  <div className="form-group flex-grow-1">
                    <label htmlFor="amount">
                      Amount{" "}
                      {fields && fields.length > 0 && (
                        <span style={{ fontStyle: "italic" }}>
                          Original=${originalAmount}
                        </span>
                      )}
                    </label>

                    <Amount
                      control={control}
                      defaultValue={props.transaction.amount?.toFixed(2)}
                      fields={fields}
                      errors={errors}
                    />

                    {errors.amount && (
                      <div className="invalid-feedback">
                        {errors.amount.message}
                      </div>
                    )}
                  </div>
                  {props.transaction._id && (
                    <div style={{ position: "relative", padding: "20px" }}>
                      <i
                        className="fas fa-plus"
                        style={{
                          position: "absolute",
                          top: "38px",
                          fontSize: "1.5rem",
                          cursor: "pointer",
                        }}
                        onClick={() => append({ amount: null })}
                      ></i>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="expenseGroup">Expense Group</label>
                  <select
                    className={
                      "form-control" +
                      (errors.expenseGroup?._id ? " is-invalid" : "")
                    }
                    {...register('expenseGroup._id',{
                      required: "Expense Group is required",
                    })}
                  >
                    <option value=""></option>
                    {expenseGroups &&
                      expenseGroups.map((expenseGroup) => (
                        <option key={expenseGroup._id} value={expenseGroup._id}>
                          {expenseGroup.fullName}
                        </option>
                      ))}
                  </select>
                  {errors.expenseGroup?._id && (
                    <div className="invalid-feedback">
                      {errors.expenseGroup._id.message}
                    </div>
                  )}
                </div>
              </div>
              {/* additional transactions */}
              {fields.map((item, index) => (
                <div
                  className="border border-secondary p-3 my-1"
                  style={{ position: "relative" }}
                  key={item.id}
                >
                  <i
                    className="fas fa-times"
                    style={{
                      position: "absolute",
                      right: "5px",
                      top: "5px",
                      fontSize: "1.5rem",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      remove(index);
                    }}
                  ></i>
                  <div className="form-group">
                    <label htmlFor={`additionalTransactions[${index}].amount`}>
                      Amount
                    </label>
                    <input
                      type="text"
                      className={
                        "form-control" +
                        (errors.additionalTransactions &&
                        errors.additionalTransactions[index] &&
                        errors.additionalTransactions[index].amount
                          ? " is-invalid"
                          : "")
                      }
                      // defaultValue={item.amount}
                      {...register(`additionalTransactions[${index}].amount`,{
                        required: "Amount is required",
                      })}
                      // onChange={debounce(calculateAmount, 500)}
                      // onChange={debounce(recalculateAmount, 500)}
                    />
                    {errors.additionalTransactions &&
                      errors.additionalTransactions[index] &&
                      errors.additionalTransactions[index].amount && (
                        <div className="invalid-feedback">
                          {errors.additionalTransactions[index].amount.message}
                        </div>
                      )}
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor={`additionalTransactions[${index}].expenseGroupId`}
                    >
                      Expense Group
                    </label>
                    <select
                      className={
                        "form-control" +
                        (errors.additionalTransactions &&
                        errors.additionalTransactions[index] &&
                        errors.additionalTransactions[index].expenseGroupId
                          ? " is-invalid"
                          : "")
                      }
                      {...register(`additionalTransactions[${index}].expenseGroupId`,{
                        required: "Expense Group is required",
                      })}
                    >
                      <option value=""></option>
                      {expenseGroups &&
                        expenseGroups.map((expenseGroup) => (
                          <option key={expenseGroup._id} value={expenseGroup._id}>
                            {expenseGroup.fullName}
                          </option>
                        ))}
                    </select>
                    {errors.additionalTransactions &&
                      errors.additionalTransactions[index] &&
                      errors.additionalTransactions[index].expenseGroupId && (
                        <div className="invalid-feedback">
                          {
                            errors.additionalTransactions[index].expenseGroupId
                              .message
                          }
                        </div>
                      )}
                  </div>
                </div>
              ))}

              <div className="form-group">
                <label htmlFor="fromAccount">From Account</label>
                <select
                  className={
                    "form-control" + (errors.fromAccount ? " is-invalid" : "")
                  }
                  defaultValue={props.transaction.fromAccount}
                  {...register('fromAccount',{
                    validate: (value) => {
                      const { toAccount } = getValues();
                      if (!value && !toAccount)
                        return "A From or To Account is required";
                      else return true;
                    },
                  })}
                  // onChange={(e) => setFromAccount(e.currentTarget.value)}
                >
                  <option></option>
                  <option>Joint Checking</option>
                  <option>Mark's Savings</option>
                  <option>Mell's Savings</option>
                </select>
                {errors.fromAccount && (
                  <div className="invalid-feedback">
                    {errors.fromAccount.message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="toAccount">To Account</label>
                <select
                  className={
                    "form-control" + (errors.toAccount ? " is-invalid" : "")
                  }
                  defaultValue={props.transaction.toAccount}
                  {...register('toAccount',{
                    validate: (value) => {
                      const { fromAccount } = getValues();
                      if (!value && !fromAccount)
                        return "A From or To Account is required";
                      else return true;
                    },
                  })}
                >
                  <option></option>
                  <option>Joint Checking</option>
                  <option>Mark's Savings</option>
                  <option>Mell's Savings</option>
                </select>
                {errors.toAccount && (
                  <div className="invalid-feedback">
                    {errors.toAccount.message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="tempFromAccount">Temp From Account</label>
                <select
                  className={
                    "form-control" +
                    (errors.tempFromAccount ? " is-invalid" : "")
                  }
                  defaultValue={props.transaction.tempFromAccount}
                  {...register('tempFromAccount')}
                >
                  <option></option>
                  <option>Mark's Savings</option>
                  <option>Mell's Savings</option>
                  <option>Joint Visa</option>
                </select>
                {errors.tempFromAccount && (
                  <div className="invalid-feedback">
                    {errors.tempFromAccount.message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="comment">Comment</label>
                <textarea
                  className={
                    "form-control" + (errors.comment ? " is-invalid" : "")
                  }
                  defaultValue={props.transaction.comment}
                  {...register('comment')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="project._id">Project</label>
                <select
                  className={
                    "form-control" + (errors.project?._id ? " is-invalid" : "")
                  }
                  defaultValue={
                    props.transaction.project
                      ? props.transaction.project._id
                      : ""
                  }
                  {...register('project._id')}
                >
                  <option value=""></option>
                  {projects &&
                    projects.map((project) => (
                      <option key={project._id} value={project._id}>{project.name}</option>
                    ))}
                </select>
                {errors.project?._id && (
                  <div className="invalid-feedback">
                    {errors.project._id.message}
                  </div>
                )}
              </div>

              <div className="form-group">
                <FilePond
                  files={files}
                  onupdatefiles={setFiles}
                  onactivatefile={function (file) {
                    console.log(file);
                    window.open(`${window.location.origin}/api/download?file=${file.source}/${encodeURIComponent(file.filename)}`);
                  }}
                  allowMultiple={true}
                  server={{
                    process: "/api/upload",
                    remove: (source, load, error) => {
                      if(window.confirm('Are you sure you want to delete this file?')) deleteFile(source);
                    },
                  }}
                  name="files"
                  labelIdle='Drag & Drop your files or <span className="filepond--label-action">Browse</span>'
                />
              </div>

              { props.transaction.createdAt && 
                <div className="form-group">
                  {/* format the createdAt date using Intl.DateTimeFormat */}
                  <label style={{fontSize:'0.75rem'}}>created {Intl.DateTimeFormat('en-CA').format(new Date(props.transaction.createdAt))} ({props.transaction.originalHash})</label>
                </div>
              }

              {/* {files && files.map(file => console.log(file))} */}
            </form>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        {props.transaction._id ? (
          <button type="button" className="btn btn-light btn-sm" onClick={onDelete}>
            Delete
          </button>
        ) : (
          <div></div>
        )}
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleSubmit(onSubmit)()}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}
