import React, { useState, useEffect, useContext } from "react";
import ReactDatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';
import { useForm, Controller } from "react-hook-form";

export default function BudgetDef(props) {
  const [expenseGroups, setExpenseGroups] = useState(null);
  const [isSaving, setIsSaving] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    control,
  } = useForm();
  watch();

  const onSubmit = (data) => {
    setIsSaving(true);
    fetch("/api/budget-def", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        props.submitCallback();
      })
      .catch((err) => {
        console.error(err);
        setIsSaving(false);
      });
  };

  const onDelete = () => {
    if(window.confirm('Are you sure you want to delete?')){
      setIsSaving(true);
      fetch("/api/delete-budget-def", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({_id:props.budgetDef._id}),
      }).then((response) => {
        if (!response.ok) throw Error(response.statusText);
        props.deleteCallback();
      })
      .catch((err) => {
        console.error(err);
        setIsSaving(false);
      });
    }
  };

  const fetchExpenseGroups = () => {
    fetch("/api/expense-groups")
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        return response.json();
      })
      .then((expenseGroups) => {
        setExpenseGroups(expenseGroups);
        setValue("expenseGroup._id", props.budgetDef.expenseGroup._id);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchExpenseGroups();
  }, []);

  return (
    <Modal
        show={props.budgetDef ? true : false}
        onHide={props.closeCallback}
        centered
        scrollable
        backdrop="static"
        keyboard={false}
    >
        <Modal.Header closeButton>
        <Modal.Title>
            {props.budgetDef && props.budgetDef._id ? (
            <div>Edit Budget Definition</div>
            ) : (
            <div>New Budget Definition</div>
            )}
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div>
      {props.budgetDef && (
        <form id="budgetDefForm" onSubmit={handleSubmit(onSubmit)}>
          <input
              type="hidden"
              defaultValue={props.budgetDef._id}
              {...register('_id')}
            />
          <div className="form-group">
            <label htmlFor="account">Account</label>
            <select
              className={"form-control" + (errors.account ? " is-invalid" : "")}
              defaultValue={
                props.budgetDef.account ? props.budgetDef.account : ""
              }
              {...register('account',{
                required: "Account is required",
              })}
            >
              <option>Joint Checking</option>
              <option>Mark's Savings</option>
              <option>Mell's Savings</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className={"form-control" + (errors.name ? " is-invalid" : "")}
              defaultValue={props.budgetDef.name}
              {...register('name',{
                required: "Name is required",
              })}
            />
            {errors.name && (
              <div className="invalid-feedback">{errors.name.message}</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="expenseGroup">Expense Group</label>
            <select
              className={
                "form-control" + (errors.expenseGroup?._id ? " is-invalid" : "")
              }
              defaultValue={
                props.budgetDef.expenseGroup
                  ? props.budgetDef.expenseGroup._id
                  : ""
              }
              {...register('expenseGroup._id',{
                required: "Expense Group is required",
              })}
            >
              <option value=""></option>
              {expenseGroups &&
                expenseGroups.map((expenseGroup) => (
                  <option value={expenseGroup._id}>
                    {expenseGroup.fullName}
                  </option>
                ))}
            </select>
            {errors.expenseGroup?._id && (
              <div className="invalid-feedback">
                {errors.expenseGroup._id.message}
              </div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="type">Type</label>
            <select
              className={"form-control" + (errors.type ? " is-invalid" : "")}
              defaultValue={props.budgetDef.type ? props.budgetDef.type : ""}
              {...register('type',{
                required: "Type is required",
              })}
            >
              <option>STATIC</option>
              <option>STATIC_WEEKLY</option>
              <option>DYNAMIC_MONTH</option>
              <option>DYNAMIC_YEAR</option>
              <option>STATIC_WEEKLY_PREVIOUS_ABSOLUTE</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="amount">Amount</label>
            <input
              type="text"
              className={"form-control" + (errors.amount ? " is-invalid" : "")}
              defaultValue={props.budgetDef.amount}
              {...register('amount',{
                validate: (value) => {
                  const { type } = getValues();
                  if (!value && type === "STATIC")
                    return "An amount is required";
                  else return true;
                },
              })}
            />
            {errors.amount && (
              <div className="invalid-feedback">{errors.amount.message}</div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="monthFrom">Month From</label>
            <input
              type="text"
              className={
                "form-control" + (errors.monthFrom ? " is-invalid" : "")
              }
              defaultValue={props.budgetDef.monthFrom}
              {...register('monthFrom')}
            />
          </div>
          <div className="form-group">
            <label htmlFor="monthTo">Month To</label>
            <input
              type="text"
              className={"form-control" + (errors.monthTo ? " is-invalid" : "")}
              defaultValue={props.budgetDef.monthTo}
              {...register('monthTo')}
            />
          </div>
          
        </form>
      )}
    </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
            { props.budgetDef._id ? <button type="button" className="btn btn-light btn-sm" onClick={onDelete}>
              Delete
            </button> : <div></div>}
            <button type="button" className="btn btn-primary" onClick={() => handleSubmit(onSubmit)()}>
              Save
            </button>
        </Modal.Footer>
    </Modal>
    
  );
}
