import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import Transaction from "./Transaction";
import TransactionTable from "./TransactionsTable";
import Attachments from './Attachments';
import ProcessRule from './ProcessRule';
import { useLocation } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

export default function Transactions(props) {
  const [rowData, setRowData] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [account, setAccount] = useState(null);
  const [transactionToEdit, setTransactionToEdit] = useState(null);
  const [processRuleToEdit, setProcessRuleToEdit] = useState(null);
  const [attachmentTransaction, setAttachmentTransaction] = useState(null);
  const [loadingTransactions, setLoadingTransactions] = useState(true);
  const [tableFilter, setTableFilter] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const location = useLocation();
  const accountInput = useRef(null);
  const searchInput = useRef(null);

  const fetchTransactions = async function () {
    if(!startDate && !endDate && !account && !searchText) return;
    setLoadingTransactions(true);
    const transactionsResponse = await fetch(
      `/api/transactions?startDate=${startDate}&endDate=${endDate}&account=${account}${searchText ? `&searchText=${searchText}` : ''}`,
      { credentials: "include" }
    );
    const transactions = await transactionsResponse.json();
    setRowData(transactions.data);
    setLoadingTransactions(false);
  };

  useEffect(() => {
    /*** default filters ***/
    // const date = new Date();
    // setStartDate(
    //   new Date(date.getFullYear(), date.getMonth() - 1, date.getDate(), 0, 0, 0)
    // );
    // setEndDate(date);
    // setAccount("Joint Checking");
    /*** end default filters ***/
    setDefaultFilter();

    const searchParams = new URLSearchParams(location.search);
    for (const [key, value] of searchParams) {
      switch (key) {
        case "startDate":
          setStartDate(new Date(value));
          break;
        case "endDate":
          setEndDate(new Date(value));
          break;
        case "expenseGroup.fullName":
          const newFilter = tableFilter || [];
          newFilter.push({ id: key, value });
          setTableFilter(newFilter);
          break;
        case "account":
          setAccount(value);
          document.querySelector('#account').value = value;
          break;
        default:
          break;
      }
    }
  }, [location]);

  useEffect(() => {
    fetchTransactions();
  }, [account, startDate, endDate, searchText]);

  const editTransaction = function (row) {
    console.log(row);
    setTransactionToEdit(row.original);
  };

  const editProcessRule = function (rowData) {
    setProcessRuleToEdit(rowData.processRule || {matchText: rowData.comment, loadedFromScrapeAccount: rowData.loadedFromScrapeAccount, expenseGroup: rowData.expenseGroup._id});
  };

  const refreshAfterFormAction = () => {
    fetchTransactions();
    setTransactionToEdit(null);
    setProcessRuleToEdit(null);
  };

  const showAttachments = (row) => {
    console.log(row);
    setAttachmentTransaction(row);
  }

  const doSearch = () => {
    setStartDate(null);
    setEndDate(null);
    setAccount(null);
    accountInput.current.value="";
    setSearchText(searchInput.current.value);
  }

  const setDefaultFilter = () => {
    const date = new Date();
    setStartDate(
      new Date(date.getFullYear(), date.getMonth() - 1, date.getDate(), 0, 0, 0)
    );
    setEndDate(date);
    setAccount("Joint Checking");
  }

  const resetSearch = () => {
    setSearchText(null);
    searchInput.current.value=null;
    accountInput.current.selectedIndex=0;
    setDefaultFilter();
  }

  return (
    <div>
      <div className="p-3 mb-2 bg-light text-dark">
        <form>
          <input type="hidden" id="expenseGroupId" name="expenseGroupId" />
          <div className="form-row">
            <div className="col-sm-6 col-xl">
              <div className="form-group">
                <label htmlFor="startDate">Start Date</label>
                <DatePicker
                  wrapperClassName="form-control"
                  className="form-control"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  disabled={searchText}
                />
              </div>
            </div>
            <div className="col-sm-6 col-xl">
              <div className="form-group">
                <label htmlFor="endDate">End Date</label>
                <DatePicker
                  wrapperClassName="form-control"
                  className="form-control"
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  disabled={searchText}
                />
              </div>
            </div>
            <div className="col-sm-6 col-xl">
              <div className="form-group">
                <label htmlFor="account">Account</label>
                <select
                  className="form-control"
                  id="account"
                  onChange={(e) => setAccount(e.currentTarget.value)}
                  ref={accountInput}
                  disabled={searchText}
                >
                  <option>Joint Checking</option>
                  <option>Joint Visa</option>
                  <option>Mark's Savings</option>
                  <option>Mell's Savings</option>
                </select>
              </div>
            </div>
            <div className="col-sm-6 col-xl">
              <div className="form-group">
                <label htmlFor="account">Search</label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    ref={searchInput}
                  />
                  <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button" onClick={resetSearch}>
                      <i className="fas fa-undo"></i>
                    </button>
                  </div>
                  <div className="input-group-append">
                    <button className="btn btn-outline-secondary" type="button" onClick={doSearch}>
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-row">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setTransactionToEdit({ date: new Date() })}
            >
              New Transaction
            </button>
          </div>
        </form>
      </div>
      {rowData && (
        <TransactionTable
          data={rowData}
          loading={loadingTransactions}
          editRecord={editTransaction}
          editProcessRule={editProcessRule}
          account={account}
          filter={tableFilter}
          setFilter={setTableFilter}
          showAttachments={showAttachments}
        />
      )}

      {transactionToEdit && (
        <Transaction
          transaction={transactionToEdit}
          submitCallback={refreshAfterFormAction}
          deleteCallback={refreshAfterFormAction}
          closeCallback={() => setTransactionToEdit(null)}
        />
      )}

      {attachmentTransaction && (
        <Attachments
          transaction={attachmentTransaction}
          closeCallback={() => setAttachmentTransaction(null)}
        />
      )}

      {processRuleToEdit && (
        <ProcessRule 
          processRule={processRuleToEdit} 
          closeCallback={() => setProcessRuleToEdit(null)} 
          submitCallback={refreshAfterFormAction}
          deleteCallback={refreshAfterFormAction}
        />
      )}
    </div>
  );
}
