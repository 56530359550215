import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  ResponsiveContainer,
} from "recharts";
import DatePicker from "react-datepicker";
import useExpenseGroups from "../ExpenseGroupHook";

export default function ExpenseGroupReport(props) {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [account, setAccount] = useState("Joint Checking");
  const [expenseGroup, setExpenseGroup] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [stackedExpenseGroups, setStackedExpenseGroups] = useState(null);
  const [dateRange, setDateRange] = useState("past 12 months");
  const [redirect, setRedirect] = useState(null);

  const expenseGroups = useExpenseGroups(true);

  const graphColours = [
    "#227399",
    "#42A7D5",
    "#8ECAE6",
    "#AAD7EC",
    "#2A90BF",
    "#C7E5F3",
    "#195672",
    "#68B8DE",
  ];
  const dollarFormatter = (value) => `$${value}`;

  const fetchData = async () => {
    let expensesResponse = await fetch(
      `/api/historical-monthly-expenses?fromDate=${fromDate}&toDate=${toDate}&fromAccount=${account}&expenseGroup=${expenseGroup}`,
      { credentials: "include" }
    );
    if (expensesResponse.ok) {
      let expensesJSON = await expensesResponse.json();
      let stacks = new Set();
      expensesJSON.sort((a, b) => {
        if (parseInt(a._id.year) === parseInt(b._id.year))
          return parseInt(a._id.month) - parseInt(b._id.month);
        else return parseInt(a._id.year) - parseInt(b._id.year);
      });
      expensesJSON.forEach((record) => {
        record.name = `${record._id.year}-${record._id.month}`;

        if (record.expenseGroups) {
          for (let i = 0; i < record.expenseGroups.length; i++) {
            const element = record.expenseGroups[i];
            stacks.add(element.name);
            record[element.name] = Math.round(element.totalAmount);
          }
          record.totalAmount = Math.round(record.totalAmount);
        } else {
          record.totalAmount0 = 0;
        }
      });

      console.log(expensesJSON);
      console.log(stacks);
      setStackedExpenseGroups([...stacks]);
      setReportData(expensesJSON);
    } else {
    }
  };

  useEffect(() => {
    processDateRange("past 12 months");
  }, []);

  useEffect(() => {
    if (fromDate && toDate && account && expenseGroup) fetchData();
  }, [fromDate, toDate, account, expenseGroup]);

  const processDateRange = (range) => {
    let newFromDate = new Date();
    let newToDate = new Date();
    switch (range) {
      case "past 12 months":
        newFromDate.setMonth(newFromDate.getMonth() - 12);
        newFromDate.setDate(1);
        setFromDate(newFromDate);
        newToDate.setMonth(newToDate.getMonth() + 1);
        newToDate.setDate(0);
        setToDate(newToDate);
        break;
      case "past 2 years":
        newFromDate.setFullYear(newFromDate.getFullYear() - 1);
        newFromDate.setMonth(0);
        newFromDate.setDate(1);
        setFromDate(newFromDate);
        newToDate.setMonth(newToDate.getMonth() + 1);
        newToDate.setDate(0);
        setToDate(newToDate);
        break;
      case "past 5 years":
        newFromDate.setFullYear(newFromDate.getFullYear() - 5);
        newFromDate.setMonth(0);
        newFromDate.setDate(1);
        setFromDate(newFromDate);
        newToDate.setMonth(newToDate.getMonth() + 1);
        newToDate.setDate(0);
        setToDate(newToDate);
        break;
      case "all time":
        newFromDate.setFullYear(2008);
        newFromDate.setMonth(7);
        newFromDate.setDate(1);
        setFromDate(newFromDate);
        newToDate.setMonth(newToDate.getMonth() + 1);
        newToDate.setDate(0);
        setToDate(newToDate);
        break;
      case new Date().getFullYear():
      case new Date().getFullYear() - 1:
      case new Date().getFullYear() - 2:
      case new Date().getFullYear() - 3:
      case new Date().getFullYear() - 4:
      case new Date().getFullYear() - 5:
        newFromDate.setFullYear(range);
        newFromDate.setMonth(0);
        newFromDate.setDate(1);
        setFromDate(newFromDate);
        newToDate = new Date(newFromDate.getFullYear() + 1, 0, 0);
        setToDate(newToDate);
        break;
      default:
        break;
    }
    setDateRange(range);
  };

  function expenseClicked(data, index) {
    if (data.payload.expenseGroups.length === 1) {
      const expenseGroup = data.payload.expenseGroups[0].fullName;
      const startDate = new Date(
        data.payload._id.year,
        data.payload._id.month - 1,
        1
      );
      const endDate = new Date(
        data.payload._id.year,
        data.payload._id.month,
        0
      );
      setRedirect(
        <Redirect
          to={`/transactions?expenseGroup.fullName=${expenseGroup}&startDate=${startDate}&endDate=${endDate}`}
        />
      );
    }
  }

  return (
    <div>
      {redirect}
      <div className="p-3 mb-2 bg-light text-dark">
        <h3>Expense Group Report</h3>
        <form>
          <div className="form-row">
            <div className="col">
              <div className="form-group">
                <label for="expenseGroup">Select Expense Group</label>
                <select
                  className="form-control"
                  id="expenseGroup"
                  onChange={(e) => setExpenseGroup(e.currentTarget.value)}
                >
                  <option></option>
                  {expenseGroups &&
                    expenseGroups.map((group) => (
                      <option value={group._id}>{group.fullName}</option>
                    ))}
                </select>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label for="account">Account</label>
                <select
                  className="form-control"
                  id="account"
                  onChange={(e) => setAccount(e.currentTarget.value)}
                >
                  <option>Joint Checking</option>
                  <option>Joint Visa</option>
                  <option>Mark's Savings</option>
                  <option>Mell's Savings</option>
                </select>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="col">
              {[
                "past 12 months",
                "past 2 years",
                "past 5 years",
                "all time",
                new Date().getFullYear(),
                new Date().getFullYear() - 1,
                new Date().getFullYear() - 2,
                new Date().getFullYear() - 3,
                new Date().getFullYear() - 4,
                new Date().getFullYear() - 5,
              ].map((range) => (
                <span
                  className={`badge badge-pill mr-1 ${
                    dateRange === range ? "badge-primary" : "badge-secondary"
                  }`}
                  style={{ cursor: "pointer" }}
                  onClick={() => processDateRange(range)}
                >
                  {range}
                </span>
              ))}
            </div>
          </div>
        </form>
      </div>

      {/* GRAPH */}
      <div className="row">
        <div className="col-8">
          {reportData && (
            <ResponsiveContainer width="100%" height={600}>
              <BarChart data={reportData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis tickFormatter={dollarFormatter} />
                <Tooltip />
                {stackedExpenseGroups.map((stack, i) => (
                  <Bar
                    dataKey={stack}
                    fill={graphColours[i]}
                    stackId="a"
                    onClick={expenseClicked}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
        <div className="col" style={{ fontSize: "2rem" }}>
          {reportData && (
            <div>
              Total: $
              {reportData
                .reduce(
                  (sum, monthExpense) =>
                    sum +
                    (monthExpense.expenseGroups
                      ? monthExpense.expenseGroups.reduce(
                          (groupSum, expenseGroup) =>
                            groupSum + expenseGroup.totalAmount,
                          0
                        )
                      : 0),
                  0
                )
                .toFixed(2)}{" "}
            </div>
          )}
          {reportData && (
            <div>
              Average Month: $
              {(
                reportData.reduce(
                  (sum, monthExpense) =>
                    sum +
                    (monthExpense.expenseGroups
                      ? monthExpense.expenseGroups.reduce(
                          (groupSum, expenseGroup) =>
                            groupSum + expenseGroup.totalAmount,
                          0
                        )
                      : 0),
                  0
                ) / reportData.length
              ).toFixed(2)}{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
