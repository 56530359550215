import React, { useState, useEffect } from "react";
import AmountCard from "./AmountCard";
import Expenses from "./Expenses";
import Transaction from "./Transaction";
import ProjectDashboard from "./ProjectDashboard";
import StagedTransactions from './StagedTransactions';

export default function Home(props) {
  let date = new Date();
  const [loading, setLoading] = useState(false);
  const [monthExpenses, setMonthExpenses] = useState(null);
  const [projectExpenses, setProjectExpenses] = useState(null);
  const [amounts, setAmounts] = useState(null);
  const [amountsOwing, setAmountsOwing] = useState(null);
  const [account, setAccount] = useState("Joint Checking");
  const [currentMonth, setCurrentMonth] = useState(
    new Intl.DateTimeFormat("en-CA").format(
      new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0)
    )
  );
  const [newTransaction, setNewTransaction] = useState(null);
  const [stagedTransactionsGrouped, setStagedsTransactionsGrouped] = useState(
    null
  );

  const buildMonthSelector = function () {
    return (
      <div>
        <select
          className="form-control"
          id="month"
          onChange={(e) => setCurrentMonth(e.currentTarget.value)}
        >
          {Array(24)
            .fill()
            .map((_, i) => (
              <option
                value={new Intl.DateTimeFormat("en-CA").format(
                  new Date(date.getFullYear(), date.getMonth() - i, 1, 0, 0, 0)
                )}
              >
                {new Intl.DateTimeFormat("default", {
                  year: "numeric",
                  month: "short",
                }).format(
                  new Date(date.getFullYear(), date.getMonth() - i, 1, 0, 0, 0)
                )}
              </option>
            ))}
        </select>
      </div>
    );
  };

  async function fetchMonthlyValues() {
    let theAmounts = [];
    // const balanceResponse = await fetch(`/api/balance?account=${account}`, {
    //   credentials: "include",
    // });
    // if (balanceResponse.ok)
    //   theAmounts.push({
    //     name: "Balance",
    //     amount: await balanceResponse.text(),
    //   });
    // else console.error(balanceResponse.statusText);

    const expensesResponse = await fetch(
      `/api/month-expenses?month=${currentMonth}`,
      { credentials: "include" }
    );
    if (expensesResponse.ok) {
      const expensesJSON = await expensesResponse.json();
      theAmounts.push({
        name: "Debit",
        amount: expensesJSON.reduce(
          (accumulator, currentValue) =>
            accumulator +
            (currentValue.totalAmount ? parseInt(currentValue.totalAmount) : 0),
          0
        ),
      });
      setMonthExpenses(expensesJSON);
      theAmounts.push({
        name: "Budget",
        amount: expensesJSON.reduce(
          (accumulator, currentValue) =>
            accumulator + parseFloat(currentValue.budgetAmount),
          0
        ),
      });
    } else console.error(expensesResponse.statusText);
    setAmounts(theAmounts);
  }

  function fetchStagedTransactions() {
    fetch("/api/staged-transactions-grouped")
      .then((response) => response.json())
      .then((stagedTransactions) => {
        setStagedsTransactionsGrouped(stagedTransactions)
      });
  }

  async function fetchValues() {
    const projectExpensesResponse = await fetch(
      `/api/project-expenses?account=${account}`,
      { credentials: "include" }
    );
    if (projectExpensesResponse.ok) {
      const projectExpensesJSON = await projectExpensesResponse.json();
      setProjectExpenses(projectExpensesJSON);
    }
    const jointVisaBalanceResponse = await fetch(
      "/api/getTempBalance?account=Joint Visa",
      { credentials: "include" }
    );
    const jointVisaBalance = await jointVisaBalanceResponse.text();
    const markSavingsBalanceResponse = await fetch(
      "/api/getTempBalance?account=Mark's Savings",
      { credentials: "include" }
    );
    const markSavingsBalance = await markSavingsBalanceResponse.text();
    const mellSavingsBalanceResponse = await fetch(
      "/api/getTempBalance?account=Mell's Savings",
      { credentials: "include" }
    );
    const mellSavingsBalance = await mellSavingsBalanceResponse.text();
    setAmountsOwing([
      { name: "Joint Visa", amount: jointVisaBalance, clearValue: true },
      { name: "Mark's Savings", amount: markSavingsBalance, clearValue: true },
      { name: "Mell's Savings", amount: mellSavingsBalance, clearValue: true },
    ]);
    fetchStagedTransactions();
  }

  const refreshAfterFormAction = () => {
    fetchValues();
    fetchMonthlyValues();
    setNewTransaction(null);
  };

  useEffect(() => {
    fetchValues();
  }, []);

  useEffect(() => {
    fetchMonthlyValues();
  }, [currentMonth]);

  return (
    <div className="p-3">
        { stagedTransactionsGrouped && stagedTransactionsGrouped.length > 0 && <StagedTransactions transactions={stagedTransactionsGrouped}/>}
      <div className="mb-2" style={{ maxWidth: "200px" }}>
        {buildMonthSelector()}
      </div>
      <div className="row">
        <div className="col-sm">
          <AmountCard title="Joint Checking" amounts={amounts} />
          <AmountCard
            title="Temp Balances (owing)"
            amounts={amountsOwing}
            actionCallback={fetchValues}
          />
        </div>
        <div className="col-sm">
          <div className="card bg-light mb-3">
            <div className="card-header" id="expenses">
              Expenses
            </div>
            <div id="expensesTableDiv" className="card-body">
              <Expenses
                monthlyExpenses={monthExpenses}
                newTransactionCallback={(expenseGroupId) =>
                  setNewTransaction({
                    date: new Date(),
                    expenseGroup: { _id: expenseGroupId },
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="col-sm">
          <div className="card bg-light mb-3">
            <div className="card-header" id="projects">
              Projects
            </div>
            <div id="projectTableDiv" className="card-body">
              {projectExpenses && (
                <ProjectDashboard
                  projectExpenses={projectExpenses.filter(
                    (projectExpense) => projectExpense.project.status === "Open"
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {newTransaction && (
        <Transaction
          transaction={newTransaction}
          submitCallback={refreshAfterFormAction}
          deleteCallback={refreshAfterFormAction}
          closeCallback={() => setNewTransaction(null)}
        />
      )}
    </div>
  );
}
