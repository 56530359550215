import React, { useState, useEffect, useContext } from "react";

export default function ProjectDashboard(props){
    return (
        <table className="table table-sm">
            <tbody>
                { props.projectExpenses && props.projectExpenses.map(projectExpense => 
                    <tr>
                        <td>
                        <div>
                            <div>
                                    { projectExpense.project.name }
                            </div>
                            <div className="progress" style={{backgroundColor: 'darkgray'}}>
                                <div className={`progress-bar ${projectExpense.status === 'danger' ? 'bg-danger' : projectExpense.status === 'warning' ? 'bg-warning' : 'bg-success'}`} role="progressbar" style={{width: `${projectExpense.percentBudget}%`,  'ariaValuenow':'25', 'ariaValuemin':'0', 'ariaValuemax':'100'}}>
                                    { `$${projectExpense.totalAmount} / $${projectExpense.project.budgetAmount}` }
                                </div>
                            </div>
                        </div>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}