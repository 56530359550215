import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./login.scss";

export default function Login(props) {
  const [changePassword, setChangePassword] = useState(false);
  const [formError, setFormError] = useState(null);

  const { register, handleSubmit, formState: { errors }, getValues } = useForm();

  const onSubmit = (data) => {
    fetch("/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) throw Error("Login failed");
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
        setFormError(err.message);
      });
  };

  useEffect(() => {
    document.querySelector('input[name="username"]').focus();
  }, []);

  return (
    <div className="login">
      <div class="gradient" style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        background: 'radial-gradient(circle, #aeb4ee 0%, #fa9805 100%)'}}></div>
      <div className="pattern" style={{position:'absolute'}}>
        <h1>Household</h1>
        <div className="main-agile">
          <div className="content-wthree">
            <div className="circle-w3layouts"></div>
            <h2>Login</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="inputs-w3ls">
                <i className="fa fa-user" aria-hidden="true"></i>
                <input
                  type="text"
                  placeholder="Username"
                  {...register('username',{
                    required: "Username is required",
                  })}
                />
                { errors.username && <div className="text-danger">{errors.username.message}</div> }
              </div>
              <div className="inputs-w3ls">
                <i className="fa fa-key" aria-hidden="true"></i>
                <input
                  type="password"
                  placeholder={changePassword ? "Current Password" : "Password"}
                  {...register('password',{
                    required: "Password is required",
                  })}
                />
                { errors.password && <div className="text-danger">{errors.password.message}</div> }
              </div>
              {changePassword && (
                <>
                  <div className="inputs-w3ls">
                    <i className="fa fa-key" aria-hidden="true"></i>
                    <input
                      type="password"
                      placeholder="New Password"
                      {...register('newPassword',{
                        required: "New password is required",
                      })}
                    />
                    { errors.newPassword && <div className="text-danger">{errors.newPassword.message}</div> }
                  </div>
                  <div className="inputs-w3ls">
                    <i className="fa fa-key" aria-hidden="true"></i>
                    <input
                      type="password"
                      placeholder="Repeat New Password"
                      {...register('newPassword2',{
                        required: "New password confirmation is required",
                        validate: value => value === getValues('newPassword') || 'New passwords must match'
                      })}
                    />
                    { errors.newPassword2 && <div className="text-danger">{errors.newPassword2.message}</div> }
                  </div>
                </>
              )}

              {!changePassword && (
                <div style={{ fontSize: ".75rem" }}>
                  <button
                    type="button"
                    className="btn btn-link text-white"
                    onClick={() => setChangePassword(true)}
                  >
                    change password
                  </button>
                </div>
              )}

              <input
                type="submit"
                value={changePassword ? "Set New Password" : "LOGIN"}
              />
              {formError && <div className="text-danger">{formError}</div>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}