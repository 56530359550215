import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/esm/Modal";

export default function Attachments(props) {
  return (
    <Modal
      show={props.transaction ? true : false}
      onHide={props.closeCallback}
      centered
      scrollable
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Attachments</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h2 style={{fontSize:"1.25rem"}}>
          {new Intl.DateTimeFormat('en-CA').format(new Date(props.transaction?.date))}
        </h2>
        <h2 style={{fontSize:"1.25rem"}}>
          {props.transaction?.expenseGroup.fullName}
        </h2>
        <h2 style={{fontSize:"1.25rem"}}>
          ${props.transaction?.amount.toFixed(2)}
        </h2>
        <div className="list-group">
          {props.transaction?.attachments.map((attachment) => (
            <a
              href={`/api/download?file=${
                attachment.filePath
              }/${encodeURIComponent(attachment.fileName)}`}
              className="list-group-item list-group-item-action"
              target="_blank"
              rel="noopener noreferrer"
            >
              {attachment.fileName}
            </a>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}
