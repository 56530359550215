import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm, Controller } from "react-hook-form";

export default function Project(props) {
  const [isSaving, setIsSaving] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
    control,
  } = useForm();
  watch();

  const onSubmit = (data) => {
    setIsSaving(true);
    fetch("/api/project", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText);
        props.submitCallback();
      })
      .catch((err) => {
        console.error(err);
        setIsSaving(false);
      });
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want to delete?")) {
      setIsSaving(true);
      fetch("/api/delete-project", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ _id: props.project._id }),
      })
        .then((response) => {
          if (!response.ok) throw Error(response.statusText);
          props.deleteCallback();
        })
        .catch((err) => {
          console.error(err);
          setIsSaving(false);
        });
    }
  };

  useEffect(() => {}, []);

  return (
    <Modal
      show={props.project ? true : false}
      onHide={props.closeCallback}
      centered
      scrollable
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.project && props.project._id ? (
            <div>Edit Project</div>
          ) : (
            <div>New Project</div>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {props.project && (
            <form id="projectForm" onSubmit={handleSubmit(onSubmit)}>
              <input
                type="hidden"
                defaultValue={props.project._id}
                {...register('_id')}
              />
              <div className="form-group">
                <label htmlFor="account">Account</label>
                <select
                  className={
                    "form-control" + (errors.account ? " is-invalid" : "")
                  }
                  defaultValue={
                    props.project.account ? props.project.account : ""
                  }
                  {...register('account',{
                    required: "Account is required",
                  })}
                >
                  <option>Joint Checking</option>
                  <option>Mark's Savings</option>
                  <option>Mell's Savings</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className={
                    "form-control" + (errors.name ? " is-invalid" : "")
                  }
                  defaultValue={props.project.name}
                  {...register('name',{
                    required: "Name is required",
                  })}
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name.message}</div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="budgetAmount">Budget Amount</label>
                <input
                  type="number"
                  className={
                    "form-control" + (errors.budgetAmount ? " is-invalid" : "")
                  }
                  defaultValue={props.project.budgetAmount}
                  {...register('budgetAmount',{ required: "An amount is required" })}
                />
                {errors.budgetAmount && (
                  <div className="invalid-feedback">
                    {errors.budgetAmount.message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="status">Status</label>
                <select
                  className="form-control"
                  defaultValue={props.project.status}
                  {...register('status')}
                >
                  <option>Open</option>
                  <option>Closed</option>
                </select>
              </div>
              <div className="form-group form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  {...register('displayOnHome')}
                />
                <label htmlFor="displayOnHome">Display on Home</label>
              </div>
              <div className="form-group">
                <label htmlFor="startYear">Start Year</label>
                <input
                  type="number"
                  className={
                    "form-control" + (errors.startYear ? " is-invalid" : "")
                  }
                  defaultValue={props.project.startYear}
                  {...register('startYear',{
                    validate: (value) => {
                      const { endYear } = getValues();
                      if (!value && endYear)
                        return "Please enter a start year";
                      else return true;
                    },
                  })}
                />
                {errors.startYear && (
                  <div className="invalid-feedback">
                    {errors.startYear.message}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="endYear">End Year</label>
                <input
                  type="number"
                  className={
                    "form-control" + (errors.endYear ? " is-invalid" : "")
                  }
                  defaultValue={props.project.endYear}
                  {...register('endYear',{
                    validate: (value) => {
                      const { startYear } = getValues();
                      if (!value && startYear)
                        return "Please enter an end year";
                      else return true;
                    },
                  })}
                />
                {errors.endYear && (
                  <div className="invalid-feedback">
                    {errors.endYear.message}
                  </div>
                )}
              </div>
            </form>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        {props.project._id ? (
          <button type="button" className="btn btn-light btn-sm" onClick={onDelete}>
            Delete
          </button>
        ) : (
          <div></div>
        )}
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleSubmit(onSubmit)()}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}
